<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="`Add Asset`" />
        </v-col>
        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="6">
                <v-select
                  :items="titles"
                  v-model="title"
                  label="Title"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                  :items="connectedTos"
                  v-model="connectedTo"
                  label="Connected To"
                  outlined
                  item-text="title"
                  item-value="_id"
                ></v-select>
              </v-col>
              <v-col md="12" v-if="title == 'Testimonials'">
                <v-text-field label="User Name" outlined v-model="fullName" />
              </v-col>
              <v-col md="12" v-if="title == 'Testimonials' && !imageUrl && !videoUrl ">
                <v-textarea
                  label="Description"
                  outlined
                  v-model="description"
                  row="5"
                />
              </v-col>
              <v-col cols="12" class="mb-5" v-if="description.length == 0">
                <div style="position: relative; width: 200px">
                  <span
                    style="
                      position: absolute;
                      top: 0;
                      right: -30px;
                      cursor: pointer;
                      background: red;
                      border-radius: 50%;
                      padding: 3px;
                    "
                  >
                    <v-icon
                      v-if="imageUrl || videoUrl"
                      style="color: #fff"
                      @click="
                        () => {
                          imageUrl = undefined;
                          videoUrl = undefined;
                        }
                      "
                      >mdi-close</v-icon
                    >
                  </span>
                  <img
                    :src="imageUrl"
                    alt=""
                    v-if="imageUrl"
                    width="200"
                    height="200"
                  />
                  <video
                    :src="videoUrl"
                    controls
                    v-if="videoUrl"
                    width="200"
                    height="200"
                  ></video>
                </div>

                <label
                  for="filePickup"
                  style="
                    border: 1px solid grey;
                    padding: 10px;
                    border-radius: 5px;
                    cursor: pointer;
                  "
                  v-if="!imageUrl && !videoUrl && title == 'Testimonials'"
                >
                  Choose Video
                </label>
                <label
                  for="filePickup"
                  style="
                    border: 1px solid grey;
                    padding: 10px;
                    border-radius: 5px;
                    cursor: pointer;
                  "
                  v-if="!imageUrl && !videoUrl && title != 'Testimonials'"
                >
                  Choose Image
                </label>

                <input
                  id="filePickup"
                  style="display: none"
                  type="file"
                  label="Image / Video"
                  outlined
                  @change="readFile"
                  :accept="title == 'Testimonials' ? 'video/*' : 'image/*'"
                />
              </v-col>
              <v-col md="12" v-if="title == 'Testimonials'" class="mb-5">
                <v-rating
                  v-model="rating"
                  empty-icon="mdi-star-outline"
                  full-icon="mdi-star"
                  half-icon="mdi-star-half"
                  size="40"
                  background-color="grey"
                  color="amber"
                />
              </v-col>
              <v-col md="12" v-if="title == 'Testimonials'" class="mb-5">
                <image-uploader
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="1"
                  doNotResize="gif"
                  :autoRotate="true"
                  outputFormat="file"
                  @input="setImage"
                  class="imagebox"
                >
                  <label for="fileInput" slot="upload-label">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                        ></path>
                      </svg>
                    </figure>
                    <span class="upload-caption">{{
                      hasImage ? "Replace" : "Click to upload user image"
                    }}</span>
                  </label>
                </image-uploader>
              </v-col>
              

              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  :loading="loading"
                >
                  Add {{ $route.params.slug }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import ImageUploader from "vue-image-upload-resize";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddAssetComponent",
  components: { PageHeader, ImageUploader },
  props: ["headtitle"],
  data: () => ({
    valid: true,
    title: undefined,
    loading: false,
    titles: [
      "HomeBanner",
      "AboutUsBanner",
      "SearchBanner",
      "Participants",
      "Recognized",
      "Certifications",
      "Testimonials",
      "PostAJob",
      "ContactUs",
      "TermsAndConditions",
      "PrivacyPolicy",
      "Blogs",
      "Training",
    ],
    connectedTos: [
      {
        title: "Page",
        _id: "page",
      },
    ],
    connectedTo: undefined,
    snackbar: false,
    image: undefined,
    imageUrl: "",
    videoUrl: "",
    description: "",
    fileType: "images",
    rating: 5,
    fullName: undefined,
    userImage: undefined,
  }),
  methods: {
    ...mapActions(["postAsset", "getTrainingCategories"]),
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let fd = new FormData();
        fd.append("title", this.title);
        fd.append("connectedTo", this.connectedTo);
        if (this.title == "Testimonials") {
          fd.append("description", this.description);
          fd.append("rating", this.rating);
          fd.append("fullName", this.fullName);
        }

        if (this.image) {
          fd.append("image", this.image);
        }
        if (this.userImage) {
          fd.append("userImage", this.userImage);
        }

        fd.append("fileType", this.fileType);
        fd.append("imageFile", "banner");

        await this.postAsset(fd);
        this.$router.push("/dashboard/assets");
        this.loading = false;
      }
      this.snackbar = true;
    },
    setImage: function (output) {
      this.hasImage = true;
      this.userImage = output;
    },
    readFile(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (file.type.includes("image")) {
            this.fileType = "images";
            this.image = file;
            this.videoUrl = undefined;
            this.imageUrl = e.target.result;
          } else {
            this.fileType = "videos";
            this.image = file;
            this.imageUrl = undefined;
            this.videoUrl = e.target.result;
          }
        };
        reader.readAsDataURL(file);
      }
    },
  },

  computed: {
    ...mapGetters(["allTrainingCategories"]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  async created() {
    await this.getTrainingCategories();
    this.allTrainingCategories.results.map((item) => {
      this.connectedTos.push({
        title: item.title,
        _id: item._id,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 200px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%;
  // background: rgb(248, 248, 248);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.imagecontent {
  width: 200px;
  margin: 20px 0;
}
</style>
